import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: "Private, Low Cost Airport Transfers to Bansko",
    },
    component: Home
  },
]

const router = new VueRouter({
  mode: 'history',
  base: "/",
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
