import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify';
import "vuetify/dist/vuetify.min.css";

//import minifyTheme from 'minify-css-string'

Vue.use(Vuetify);

export default new Vuetify({
    iconfont: 'mdi',
    customProperties: true,
    theme: {
        themes: {
            light: {
              /*
                primary: '#3f6ad8',
                secondary: '#000',
                accent: '#226569',
                anchor: '#57bc8d',
                error: '#ff5252',
                warning: '#fb8c00',
                info: '#2196f3',
                success: '#4caf50',
                */
            },
        },
        options: {
            //minifyTheme
        },
    },
});
