<template>
    <v-card tile color="blue" dark class="pt-12 booking-form">
        <v-form ref="book1" v-model="formValid" class="pa-2 pa-sm-2 pa-md-4 pa-lg-16">
            <v-row>
                 <v-col cols="12" sm="12" md="6" lg="3">
                    <v-select v-model="booking.pickup_location" label="Pickup Loation" :items="sources" :rules="[fieldRequired]" @input="showConfirm=false; updateDestinations();"></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="3">
                    <v-select v-model="booking.dropoff_location" label="Drop Off Location" :items="destinations" :rules="[fieldRequired]" @input="showConfirm=false"></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="3">
                    <v-menu v-model="menu.pickup_date" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="booking.pickup_date" label="Pickup Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :rules="[fieldRequired]" @input="showConfirm=false"></v-text-field>
                        </template>
                        <v-date-picker v-model="booking.pickup_date" label="Pickup Date" @input="menu.pickup_date=false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="3">
                    <v-menu v-model="menu.pickup_time" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="booking.pickup_time" label="Pickup Time" prepend-icon="mdi-clock" readonly v-bind="attrs" v-on="on" :rules="[fieldRequired]" @input="showConfirm=false"></v-text-field>
                        </template>
                        <v-time-picker format="24h" v-model="booking.pickup_time" label="Pickup Time" @input="menu.pickup_time=false"></v-time-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="3">
                    <v-select v-model="booking.passangers" label="Passangers (Inc. Children)" :items="[1,2,3,4,5,6,7,8]" :rules="[fieldRequired]" @input="showConfirm=false"></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="3">
                    <v-radio-group v-model="booking.oneway" row>
                        <v-radio label="One Way" :value="true"></v-radio>
                        <v-radio label="Return" :value="false"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="3" v-if="!booking.oneway">
                    <v-menu v-model="menu.return_date" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="booking.return_date" label="Return Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :rules="[fieldRequired]" @input="showConfirm=false"></v-text-field>
                        </template>
                        <v-date-picker v-model="booking.return_date" label="Return Date" @input="menu.return_date=false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="3" v-if="!booking.oneway">
                    <v-menu v-model="menu.return_time" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="booking.return_time" label="Return Time" prepend-icon="mdi-clock" readonly v-bind="attrs" v-on="on" :rules="[fieldRequired]" @input="showConfirm=false"></v-text-field>
                        </template>
                        <v-time-picker format="24h" v-model="booking.return_time" label="Return Time" @input="menu.return_time=false"></v-time-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-card tile flat color="transparent" class="mx-auto">
                <v-btn x-large tile :disabled="!formValid" @click="updatePrice()" color="amber lighten-1" dark>Get Rates</v-btn>
            </v-card>
            <v-card v-if="showConfirm" tile flat color="transparent" class="mx-auto mt-6 pa-4">
                <v-card flat tile light color="transparent" max-width="400" class="mx-auto pa-4">
                    <p style="font-size:22px; color:rgb(255 202 40);" class="font-weight-bold">Total cost: {{parseFloat(booking.cost).toFixed(2)}} EUR</p>
                </v-card>
                <v-form ref="book2" v-model="bookForm" class="mt-6">
                    <v-row>
                        <v-col cols="12" md="6" lg="3">
                            <v-text-field label="Name" v-model="booking.name" :rules="[fieldRequired]" autocomplete="name"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-text-field label="Phone number" v-model="booking.phone" :rules="[fieldRequired]" autocomplete="tel"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-text-field label="Email address" v-model="booking.email" :rules="[fieldRequired, validEmail]" autocomplete="email"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-text-field label="Flight number" v-model="booking.flight_number" :rules="[fieldRequired]"></v-text-field>
                        </v-col>
                         <v-col cols="12" sm="12">
                            <v-text-field label="Notes" v-model="booking.notes" hint="You can enter any other details here"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="justify-center">
                        <v-col cols="auto">
                            <v-btn tile x-large :disabled="!bookForm" @click="confirmBooking()" color="amber lighten-1" dark aria-label="Confirm Booking">Confirm Booking</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-form>

        <v-dialog v-model="confirm.show" persistent hide-overlay v-if="confirm.show" width="800" content-class="elevation-0 rounded-0">
            <v-card flat tile >
                <v-alert :type="confirm.type" tile text outlined>
                    <p>{{confirm.text}}</p>
                    <v-btn class="mx-auto" outlined tile large @click="resetForms()" aria-label="OK">OK</v-btn>
                </v-alert>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            formValid: false,
            bookForm: false,
            confirm: {
                type: null,
                text: null,
                show: false,
            },
            showConfirm: false,
            booking: {
                name: null,
                phone: null,
                email: null,
                pickup_date: null,
                pickup_time: null,
                return_date: null,
                return_time: null,
                pickup_location: "",
                dropoff_location: "",
                passangers: null,
                oneway: true,
                notes: null,
                flight_number: null,
                cost: 0,
            },
            menu: {
                pickup_date: false,
                pickup_time: false,
                return_date: false,
                return_time: false
            },
            fieldRequired: function (val) {
                return !!val || "This is a required field!";
            },
            validEmail: function(val) {
                return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val) || "Invalid email address.";
            },
            sources: [
                {text:null, value: null, header: 'Airports', airport: true},
                {text:'Sofia Airport, Bulgaria', value:'Sofia Airport, Bulgaria', airport: true},
                {text:'Plovdiv Airport, Bulgaria', value: 'Plovdiv Airport, Bulgaria', airport: true},
                {text:'Thessaloniki Airport, Greece', value: 'Thessaloniki Airport, Greece', airport: true},
                {text:'Kavala Airport, Greece', value:'Kavala Airport, Greece', airport: true},
                {text: null, value: null, header: 'Resorts', airport: false},
                {text: 'Bansko', value: 'Bansko', airport: false},
                {text: 'Dobrinishte', value: 'Dobrinishte', airport: false},
                {text: 'Piring Golf', value: 'Pirin Golf', airport: false},
                {text: 'Razlog', value: 'Razlog', airport: false}
            ],
            destinations: [
                {text:null, value: null, header: 'Airports', airport: true},
                {text:'Sofia Airport, Bulgaria', value:'Sofia Airport, Bulgaria', airport: true},
                {text:'Plovdiv Airport, Bulgaria', value: 'Plovdiv Airport, Bulgaria', airport: true},
                {text:'Thessaloniki Airport, Greece', value: 'Thessaloniki Airport, Greece', airport: true},
                {text:'Kavala Airport, Greece', value:'Kavala Airport, Greece', airport: true},
                {text: null, value: null, header: 'Resorts', airport: false},
                {text: 'Bansko', value: 'Bansko', airport: false},
                {text: 'Dobrinishte', value: 'Dobrinishte', airport: false},
                {text: 'Piring Golf', value: 'Pirin Golf', airport: false},
                {text: 'Razlog', value: 'Razlog', airport: false}
            ],
            priceMap: [
                {source: 'Sofia Airport, Bulgaria', passangers: 8, cost: 115},
                {source: 'Sofia Airport, Bulgaria', passangers: 6, cost: 85},
                {source: 'Sofia Airport, Bulgaria', passangers: 4, cost: 80},
                {source: 'Plovdiv Airport, Bulgaria', passangers: 8 , cost: 115},
                {source: 'Plovdiv Airport, Bulgaria', passangers: 4 , cost: 85},
                {source: 'Thessaloniki Airport, Greece', passangers: 8, cost: 215},
                {source: 'Thessaloniki Airport, Greece', passangers: 4, cost: 165},
                {source: 'Kavala Airport, Greece', passangers: 8, cost: 215},
                {source: 'Kavala Airport, Greece', passangers: 4, cost: 165},
            ]
        }
    },
    methods: {
        updateDestinations() {
            let app = this;
            if(this.booking.pickup_location != null) {
                let isAirport = this.sources.filter(item => item.value == app.booking.pickup_location)[0]['airport'];
                this.destinations = this.sources.filter(item => {
                    return item.airport == !isAirport;
                })
            }
        },
        updatePrice() {
            if(this.formValid) {
                let app = this;
                this.booking.cost = 0;

                this.priceMap.forEach(item => {
                    if((app.booking.dropoff_location == item.source || app.booking.pickup_location == item.source) && app.booking.passangers <= item.passangers) {
                        app.booking.cost = item.cost;
                    }
                });

                if(this.booking.oneway == false) this.booking.cost *= 2;
                this.showConfirm = true;
            }
            else this.booking.cost = 0;
        },
        confirmBooking() {
            if(this.bookForm && this.formValid) {
                let app = this;

                this.axios.post(this.ApiEndpoint + "book.php", {
                    'booking': this.booking,
                }).then(res => {
                    if(res.data.status == 'success') {
                        app.confirm.type = 'success';
                        app.confirm.text = 'Thank you. Your booking has been confirmed. Your reference number is: ' + res.data.ref + '. You will receive a confirmation email with your reservation details.';
                        app.confirm.show = true;
                    }
                    else {
                        app.confirm.type = 'error';
                        app.confirm.text = 'Error. Some unexpected error has occurred. Your booking is not confirmed. Please call us to confirm your reservation.';
                        app.confirm.show = true;
                    }
                })
            }
        },
        resetForms() {
            this.$refs.book1.reset();
            this.$refs.book2.reset();
            this.confirm.show = false;
        }
    }
}
</script>
<style scoped>
.booking-form {
    background: url('../assets/bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 3;
}
.booking-form:after {
    content: '';
    z-index:-1;
    background: rgb(19 69 116 / 89%);
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>