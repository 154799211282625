<template>
    <v-card tile flat class="pt-12">
        <div v-if="ratings.length">
            <h1 class="my-16 h1-title text-uppercase">What Our Customers Think</h1>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="4" v-for="(rating, index) in ratings" :key="index">
                    <v-card class="elevation-4 text-left" tile>
                        <v-card-text class="rating-body">
                            <v-icon>mdi-account</v-icon> <strong>{{rating.name}}</strong> - {{rating.created}}
                            <v-rating readonly small dense :value="parseInt(rating.rating)" :length="5" empty-icon="mdi-star-outline" full-icon="mdi-star" color="indigo"></v-rating>
                            <p class="text-left mt-3">{{rating.comment}}</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <h1 class="my-16 h1-title text-uppercase">Rate Our Service</h1>
        <h4>We value your feedback. Please rate your overall experience.</h4>
        <v-card width="500" flat class="mx-auto mt-4">
            <v-form ref="rate" v-model="formValid">
                <v-row>
                    <v-col cols="12">
                        <v-rating :length="5" empty-icon="mdi-star-outline" full-icon="mdi-star" color="orange" large hover v-model="rating.rating"></v-rating>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea outlined flat rows="5" label="Your feedback / comments" v-model="rating.comment" :rules="[fieldRequired]"></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field dense outlined flat label="Your name" v-model="rating.name" :rules="[fieldRequired]"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-btn large tile outlined color="primary" class="px-12" @click="sendRating()">Submit</v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="alert.show">
                    <v-col cols="12">
                        <v-alert :type="alert.type" tile outlined text>{{alert.text}}</v-alert>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            formValid: false,
            rating: {
                name: null,
                comment: null,
                rating: 5,
            },
            alert: {
                show: false,
                text: null,
                type: null,
            },
            ratings: [],
            fieldRequired: function(val) {
                return !!val || "This is a required field.";
            },
        }
    },
    methods: {
        sendRating() {
            let app = this;

            this.axios.post(this.ApiEndpoint + "rate.php", {
                'rating': this.rating
            }).then(res => {
                if(res.data.status == 'success') {
                    app.alert.text = 'Thank you. Your rating has been sent.'
                    app.alert.type = 'success';
                    app.alert.show = true;

                    app.$refs.rate.reset();
                }
            })
        }
    },
    mounted() {
        let app = this;

        this.axios.post(this.ApiEndpoint, {
            operation: 'select',
            table: 'comments',
            fields: ['name', 'comment', 'rating', 'created'],
            where: [{deleted:{'is':null}}, {approved:{'eq':'1'}}],
            page: 1,
            limit: 12,
            order: {'RAND()':''}
        }).then(res => {
            app.ratings = res.data.data;
        });
    }
}
</script>
<style scoped>
.h1-title:before {
    content:'';
    height: 1px;
    margin-right: 10px;
    display:inline-flex;
    width: 100px;
    border-top: 1px solid #2196f3;
    vertical-align: middle;
}
.h1-title:after {
    content:'';
    height: 1px;
    margin-left: 10px;
    display: inline-flex;
    width: 100px;
    border-top: 1px solid #2196f3;
    vertical-align: middle;
}
.rating-body {
    height: 180px;
    font-size: smaller;
    overflow-y: auto;
}
.rating-body::-webkit-scrollbar {
    width: 8px;
}
.rating-body::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
@media only screen and (max-width: 960px) {
    .h1-title:before, .h1-title:after {
        display:none;
    }
}
</style>