<template>
    <v-card flat tile class="mt-16 pa-2 pa-md-3 contact" color="grey lighten-4">
        <h1 class="my-12 h1-title white--text text-uppercase">Contact Us</h1>

        <v-card tile max-width="640" class="mx-auto pa-1 pa-md-3 pa-lg-12 mt-6" style="opacity:0.7">
            <h4 class="my-6 text-uppercase">Should you have any questions, please send us a message</h4>
                    
            <v-form ref="contact" v-model="formValid" class="px-4">
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="contact.name" label="Your Name" dense :rules="[fieldRequired]" autocomplete="name"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="contact.email" label="Your Email" dense :rules="[fieldRequired, validEmail]" autocomplete="email"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="contact.phone" label="Your Phone" dense :rules="[fieldRequired]" autocomplete="tel"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="contact.message" label="Message" dense rows="3" :rules="[fieldRequired]"></v-textarea>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-btn tile x-large outlined class="px-12" color="blue lighten-2" @click="sendMessage()" aria-label="Send">Send</v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="alert.show">
                    <v-col cols="12">
                        <v-alert :type="alert.type" tile outlined text>{{alert.text}}</v-alert>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            formValid: false,
            contact: {
                name: null,
                email: null,
                phone: null,
                message: null,
            },
            alert: {
                show: false,
                text: null,
                type: null,
            },
            fieldRequired: function(val) {
                return !!val || "This is a required field.";
            },
            validEmail: function(val) {
                return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val) || "Invalid email address.";
            }
        }
    },
    methods: {
        sendMessage() {
            if(this.formValid) {
                let app = this;

                this.axios.post(this.ApiEndpoint + "contact.php", {
                    'contact': this.contact
                }).then(res => {
                    if(res.data.status == "success") {
                        app.alert.show = true;
                        app.alert.text = 'Your message has been sent.';
                        app.alert.type = 'success';

                        app.$refs.contact.reset();
                    }
                    else {
                        app.alert.show = true;
                        app.alert.text = 'Your message has not been sent.';
                        app.alert.type = 'error';
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.contact {
    background: url('../assets/contact.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 3;
}
.contact:after {
    content: '';
    z-index:-1;
    background: rgba(69, 69, 69, 0.89);
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.h1-title:before {
    content:'';
    height: 1px;
    margin-right: 10px;
    display:inline-flex;
    width: 100px;
    border-top: 1px solid #2196f3;
    vertical-align: middle;
}
.h1-title:after {
    content:'';
    height: 1px;
    margin-left: 10px;
    display: inline-flex;
    width: 100px;
    border-top: 1px solid #2196f3;
    vertical-align: middle;
}

@media only screen and (max-width: 960px) {
    .h1-title:before, .h1-title:after {
        display:none;
    }
}
</style>