<template>
    <v-app-bar elevation="0" tile app hide-on-scroll height="64">
        <div class="logo">
            <a href="https://airportbanskotransfer.com" aria-label="Airport Bansko Transfer">
                <v-img :src="require('../assets/logo.webp')" alt="Logo"></v-img>
            </a>
        </div>
        <v-spacer></v-spacer>
            
        <a href="mailto:book@airportbanskotransfer.com" class="h-link"><v-icon size="18" color="primary" class="mx-2" title="Email">mdi-email</v-icon><span class="hidden-md-and-down"> book@airportbanskotransfer.com</span></a> 
        <a href="tel:+359885888778" class="h-link"><v-icon size="18" color="primary" class="mx-1 mx-lg-2" title="Call">mdi-cellphone</v-icon><span class="hidden-md-and-down"> +359885888778</span></a>
        <a href="https://wa.me/359899239723?text=" target="_blank" class="mx-1 mx-lg-2" title="Whatsapp"><v-icon color="#25D366" size="22">mdi-whatsapp</v-icon></a>
        <a href="https://m.me/bore.terziev1" target="_blank" class="mx-1 mx-lg-2" title="Messenger"><v-icon color="#0099FF" size="22">mdi-facebook-messenger</v-icon></a>
        <a href="viber://contact?number=%2B359899239723" target="_blank" class="mx-1 mx-lg-2" title="Viber"><v-icon color="#7360f2" size="22">mdi-whatsapp</v-icon></a>
    </v-app-bar>
</template>
<script>

export default {
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
    },
    mounted() {
    }
}
</script>
<style scoped>
.h-link {
    text-decoration: none;
    text-transform: lowercase;
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    line-height: 2rem;
    font-family: Roboto,sans-serif;
    font-weight: 500;
}
.logo {
    width: 250px;
    max-width: 50%;
}
</style>