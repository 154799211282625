<template>
    <v-container fluid class="pa-0">
        <Header />
        <Social />

        <v-card tile flat class="welcome">
            <div class="heading-overlay">Reliable, low cost <br>airport transfers</div>
        </v-card>

        <v-container tile flat fluid class="main px-lg-4 px-md-2 px-sm-0">
            <h1 class="my-8 h1-title">WELCOME</h1>
            <p class="text-justify mt-4" style="font-size:18px;">Welcome to Airport Bansko Transfer, your premier choice for private airport transfers to Bansko. We offer a seamless and comfortable journey from the airport straight to your destination in Bansko, ensuring a stress-free start to your trip. Our professional drivers are experienced, punctual, and dedicated to providing you with the highest level of service. With our fleet of vehicles, we cater to individuals, families, and groups. Sit back, relax, and let us take care of your transportation needs, allowing you to fully enjoy the beauty of Bansko. Book your private airport transfer with us today.</p>

            <v-card flat tile class="mt-6">
                <h1 class="my-16 h1-title text-uppercase">Book Your Transfer</h1>
                <Booking />
            </v-card>

            <v-card flat tile class="mt-6">
                <h1 class="my-16 h1-title text-uppercase">Why Book With Us</h1>

                <v-row>
                    <v-col cols="12" sm="12" md="4" lg="3" class="pa-1 pa-sm-1 pa-md-3 pa-lg-6">
                        <v-card tile class="hover">
                            <v-card-title class="justify-center"><v-icon color="light-blue" size="72">mdi-currency-usd-off</v-icon></v-card-title>
                            <v-card-title class="justify-center">No Prepayment Needed</v-card-title>
                            <v-card-text>
                                Pay on arrival. We accept EUR, USD, GBP in cash or Revolut.
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="3" class="pa-1 pa-sm-1 pa-md-3 pa-lg-6">
                        <v-card tile class="hover">
                            <v-card-title class="justify-center"><v-icon color="light-blue" size="72">mdi-percent-outline</v-icon></v-card-title>
                            <v-card-title class="justify-center">No Hidden Fees</v-card-title>
                            <v-card-text>
                                What you see is what you pay. No additional fees for flight delays, extra luggage, fuel.
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="3" class="pa-1 pa-sm-1 pa-md-3 pa-lg-6">
                        <v-card tile class="hover">
                            <v-card-title class="justify-center"><v-icon color="light-blue" size="72">mdi-car-estate</v-icon></v-card-title>
                            <v-card-title class="justify-center">100% private transfer</v-card-title>
                            <v-card-text>
                                Vehicle reserved exclusively for you. Personalized and safe.
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="3" class="pa-1 pa-sm-1 pa-md-3 pa-lg-6">
                        <v-card tile class="hover">
                            <v-card-title class="justify-center"><v-icon color="light-blue" size="72">mdi-check-circle</v-icon></v-card-title>
                            <v-card-title class="justify-center">FREE Cancellation</v-card-title>
                            <v-card-text>
                                Cancel your booking at any time without additional fees.
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="3" class="pa-1 pa-sm-1 pa-md-3 pa-lg-6">
                        <v-card tile class="hover">
                            <v-card-title class="justify-center"><v-icon color="light-blue" size="72">mdi-thumb-up</v-icon></v-card-title>
                            <v-card-title class="justify-center">Excellent Repuation</v-card-title>
                            <v-card-text>
                                A driver will wait for you at the airport regardless of flight delays
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="3" class="pa-1 pa-sm-1 pa-md-3 pa-lg-6">
                        <v-card tile class="hover">
                            <v-card-title class="justify-center"><v-icon color="light-blue" size="72">mdi-account-tie</v-icon></v-card-title>
                            <v-card-title class="justify-center">Professional Driver</v-card-title>
                            <v-card-text>
                                English speaking professional driver to make your trip safe and pleasant
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="3" class="pa-1 pa-sm-1 pa-md-3 pa-lg-6">
                        <v-card tile class="hover">
                            <v-card-title class="justify-center"><v-icon color="light-blue" size="72">mdi-clock</v-icon></v-card-title>
                            <v-card-title class="justify-center">Meet & Greet</v-card-title>
                            <v-card-text>
                                A driver will wait for you at the airport regardless of flight delays
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="3" class="pa-1 pa-sm-1 pa-md-3 pa-lg-6">
                        <v-card tile class="hover">
                            <v-card-title class="justify-center"><v-icon color="light-blue" size="72">mdi-hand-coin</v-icon></v-card-title>
                            <v-card-title class="justify-center"> Affordable Service</v-card-title>
                            <v-card-text>
                                Low cost transfers, no waiting on taxi queues, no crowded buses.
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>

            <v-card flat tile class="mt-6">
                <h1 class="my-12 h1-title text-uppercase">How It Works</h1>

                <v-timeline :dense="$vuetify.breakpoint.smAndDown">
                    <v-timeline-item color="rgb(19 69 116)" fill-dot right>
                    <v-card width="500">
                        <v-card-title style="background:rgb(19 69 116)">
                        <h2 class="text-h4 white--text font-weight-light">
                            Step 1
                        </h2>
                        </v-card-title>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    You can book a transfer with us by filling the form above or contacting us by phone or email.
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                    </v-timeline-item>

                    <v-timeline-item color="amber lighten-1" fill-dot small>
                    <v-card width="500" style="float:right;">
                        <v-card-title class="amber lighten-1 justify-end">
                        <h2 class="text-h4 mr-4 white--text font-weight-light">
                            Step 2
                        </h2>
                        </v-card-title>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    Our driver will wait for you at the pick-up location at the scheduled time and will assist you with your luggage.
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                    </v-timeline-item>

                    <v-timeline-item color="cyan lighten-1" fill-dot right>
                    <v-card width="500">
                        <v-card-title class="cyan lighten-1">
                        <h2 class="text-h4 white--text font-weight-light">
                            Step 3
                        </h2>
                        </v-card-title>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    Sit back and relax until the driver drops you off at the desired location.
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                    </v-timeline-item>
                </v-timeline>
            </v-card>            
        </v-container>

        <Contact />

        <v-container>
            <Ratings class="mt-16" />
        </v-container>

        <v-footer dark color="grey" class="mt-12"><span class="caption mx-auto">All rights reserved. Copyright© 2024 <a href="https://airportbanskotransfer.com" aria-label="airportbanskotransfer.com">airportbanskotransfer.com</a></span></v-footer>
    </v-container>
</template>
<script>
import Header from "./Header.vue";
import Slider from "./Slider.vue";
import Booking from "./Booking.vue";
import Social from "./Social.vue";
import Ratings from "./Ratings.vue";
import Contact from "./Contact.vue";

export default {
    components: {
        Header,
        Slider,
        Booking,
        Social,
        Ratings,
        Contact,
    },
    data() {
        return {
            
        }
    }
}
</script>
<style scoped>
.main {
    max-width: 90%;
    margin:auto;
}
.welcome {
    margin-top: 64px;
    height: 560px;
    position:relative;
    overflow: hidden;
    z-index: 3;
}
.welcome:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: url('../assets/bansko-lift.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    animation: zoom-out 7s ease;
}
.hover {
    transition: transform .5s;
}
.hover:hover{
    transform: scale(1.06);
    cursor:pointer;
}
.h1-title:before {
    content:'';
    height: 1px;
    margin-right: 10px;
    display:inline-flex;
    width: 100px;
    border-top: 1px solid #2196f3;
    vertical-align: middle;
}
.h1-title:after {
    content:'';
    height: 1px;
    margin-left: 10px;
    display: inline-flex;
    width: 100px;
    border-top: 1px solid #2196f3;
    vertical-align: middle;
}
.heading-overlay {
    color: #114478;
    z-index: 9;
    font-size:60px;
    width:100%;
    text-align: left;
    padding-top: 2rem;
    width:fit-content;
    margin-left: 100px;
}

@keyframes zoom-out {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.0);
  }
}

@media only screen and (max-width: 960px) {
    .h1-title:before, .h1-title:after {
        display:none;
    }
    .main {
        max-width: 100%;
    }
    .heading-overlay {
        font-size: 32px;
        text-align: center;
        padding-top: 3rem;
        margin-left:20px;
    }
}
</style>